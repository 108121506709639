import React from 'react';
import PropTypes from 'prop-types';
import Valance from '@/components/valance';
import Img from '@/components/img';
import './TitleValance.css';

export const sortTypes = {
  titleFirst: 'title,icon,valance',
  iconFirst: 'icon,title,valance',
}


const TitleValance = ({ 
  titleText,
  valanceType,
  iconObject=null,
  sort=sortTypes.titleFirst,
  classWrapper = null
}) => {
  if (sort === sortTypes.titleFirst) {
    return (
      <div className={classWrapper ? `TitleValance ${classWrapper}` : 'TitleValance'}>
        {titleText && <span className="titleText" dangerouslySetInnerHTML={{__html: titleText }} />}
        {iconObject && <Img src={iconObject.src} alt={iconObject.alt || ''} className={`icon ${iconObject.className || ''}`} />}
        {valanceType && <Valance type={valanceType} />}
      </div>
    );
  }

  return (
    <div className={classWrapper ? `TitleValance ${classWrapper}` : 'TitleValance'}>
      {iconObject && <div className="icontv"><Img src={iconObject.src} alt={iconObject.alt || ''} className={`icon ${iconObject.className || ''}`} /></div>}
      {titleText && <span className="titleText" dangerouslySetInnerHTML={{__html: titleText }} />}
      {valanceType && <Valance type={valanceType} />}
    </div>
  );



}

TitleValance.propTypes = {
  titleText: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]),
  valanceType: PropTypes.string.isRequired,
  iconObject: PropTypes.object,
  classWrapper:PropTypes.string,
  sort: PropTypes.string,
  htmlInjected: PropTypes.bool,
}


export default TitleValance;