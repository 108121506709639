/* icons */
const buttonTrash = '/resources/icons/bot-borrar.svg';
const buttonTrashR = '/resources/icons/bot-borrar-roll.svg';
const close = '/resources/icons/bot-cerrar.svg';
const closeR = '/resources/icons/bot-cerrar-roll.svg';
const customButton = '/resources/icons/bot-edit.svg';
const customButtonR = '/resources/icons/bot-edit2.svg';
const plus = '/resources/icons/bot-mas.svg';
const plusR = '/resources/icons/bot-mas-roll.svg';
const minus = '/resources/icons/bot-menos.svg';
const minusR = '/resources/icons/bot-menos-roll.svg';
const back = '/resources/icons/bot-volver.svg';
const arrowDWhite = '/resources/icons/flecha-blanca-despliega.svg';
const arrowDGray = '/resources/icons/flecha-gris-despliega.svg';
const arrowDGold = '/resources/icons/flecha-dorada-abajo.svg';
const greyProductScript = '/resources/icons/guion-producto.svg';
const goldProductScript = '/resources/icons/guion-producto2.svg';
const yellowArrow = '/resources/icons/flecha-extra.svg';
const starOn = '/resources/icons/estrella-on.svg';
const starOff = '/resources/icons/estrella-off.svg';
const drinkIcon = '/resources/icons/icono-beber.svg';
const eatIcon = '/resources/icons/icono-comer.svg';
const eatAndDrink = '/resources/icons/bebe-y-come.svg';
const cashIcon = '/resources/icons/icono-efectivo.svg';
const cardIcon = '/resources/icons/icono-tarjeta.svg';
const paypalIcon = '/resources/icons/paypal.svg';
const orderIcon = '/resources/icons/icono-ticket.svg';
const trashIcon = '/resources/icons/icono-papelera.svg';
const splitLine = '/resources/icons/linea-separadora.svg';
const magnifyingGlassGrayIcon = '/resources/icons/lupa-gris.svg';
const magnifyingGlassWhiteIcon = '/resources/icons/lupa-blanca.svg';
const grayCircleForNumber = '/resources/icons/numero-circulo.svg';
const grayCircleNumber1 = '/resources/icons/numero-uno.svg';
const grayCircleNumber2 = '/resources/icons/numero-dos.svg';
const eatingPeople = '/resources/icons/gente-comiendo.svg';
const tableIcon = '/resources/icons/icono-mesa.svg';
const moustacheIcon = '/resources/icons/icono-bigote.svg';
const plusSymbol = '/resources/icons/signo-mas-extra.svg';


/* icons */
export const icons = {
  buttonTrash,
  buttonTrashR,
  close,
  closeR,
  customButton,
  customButtonR,
  plus,
  plusR,
  minus,
  minusR,
  back,
  arrowDWhite,
  arrowDGray,
  arrowDGold,
  greyProductScript,
  goldProductScript,
  yellowArrow,
  starOn,
  starOff,
  drinkIcon,
  eatIcon,
  eatAndDrink,
  cashIcon,
  cardIcon,
  paypalIcon,
  orderIcon,
  trashIcon,
  splitLine,
  magnifyingGlassGrayIcon,
  magnifyingGlassWhiteIcon,
  grayCircleForNumber,
  grayCircleNumber1,
  grayCircleNumber2,
  eatingPeople,
  tableIcon,
  moustacheIcon,
  plusSymbol,
}
